import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { TableFilterQueryParams } from 'types/tableFilter';
import { IUser } from 'domain/user/types';
import { NonLoginsBulkImportFormData } from 'forms/nonLoginBulkImportForm';

export type IndexParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

export type ImportParams = {
  clientId: ID;
  params: NonLoginsBulkImportFormData;
  loginUsers?: Boolean;
};

type ImportResponseData = {
  ignoredLines: number[];
  invalidLines: number[];
  totalCount: number;
};

export type ImportResponse = {
  data: ImportResponseData ;
  errorMessages?: string[];
};

export type ShowParams = {
  clientId: ID;
  userId: ID;
};

export type FilterParams = {
  clientId: ID;
  queryParams: TableFilterQueryParams;
};

const UsersRepository = {
  index({ clientId, queryParams }: IndexParams): Promise<CollectionResponse<'users', IUser[]>> {
    const url = apiRoutes.apiSiteV1ClientUsersPath(clientId);
    return Fetcher.get(url, queryParams);
  },
  show({ clientId, userId }: ShowParams): Promise<SingleResponse<'user', IUser>> {
    const url = apiRoutes.apiSiteV1ClientUserPath(clientId, userId);
    return Fetcher.get(url);
  },
  import({ clientId, params, loginUsers }: ImportParams): Promise<ImportResponse> {
    const url = apiRoutes.bulkImportApiSiteV1AdminClientUsersPath(clientId);

    return Fetcher.postFormData(url, { ...params, login_users: loginUsers });
  },
};

export default UsersRepository;
